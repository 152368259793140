import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'eaw-mini-widget-content',
    templateUrl: './mini-widget-content.component.html',
    styleUrl: './mini-widget-content.component.scss',
    standalone: true,
    imports: [ AsyncPipe ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniWidgetContentComponent {
    text = input.required({ transform: this.toPromise.bind(this) });
    subtext = input.required({ transform: this.toPromise.bind(this) });

    private toPromise(value: string | Promise<string>) {
        return value instanceof Promise ? value : Promise.resolve(value);
    }
}
